/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import api from 'services';
import { signaturesStatus, clientReportsResponse, clientReportsId, FilterDate } from 'interfaces/SignaturesStatus';
import { auditSignatureResponse, payloadSignatureLinkPdma, pdmaLinkResponse } from 'interfaces/Signature';

const path = '/signature';

export const getSignaturesStatus = async (query: FilterDate): Promise<signaturesStatus> => {
  const response = await api.get<signaturesStatus>(
    `/proposal/entities/list?startDate=${query.startDate || "''"}&endDate=${query.endDate || "''"}`,
  );
  return response.data;
};
export const clientReports = async (query: clientReportsId): Promise<clientReportsResponse> => {
  const response = await api.get<clientReportsResponse>(
    `/proposal/entities/proposalpendings?id=${query.id}&startDate=${query.startDate || "''"}&endDate=${
      query.endDate || "''"
    }`,
  );
  return response.data;
};

export const getAuditSignature = async (identifier: string): Promise<auditSignatureResponse> => {
  const response = await api.get<auditSignatureResponse>(`${path}/confia/${identifier}`);
  return response.data;
};

export const patchSignaturePdmaLink = async ({
  identifier,
  link,
  pdmaCode,
}: payloadSignatureLinkPdma): Promise<pdmaLinkResponse> => {
  const response = await api.patch<pdmaLinkResponse>(`${path}/${identifier}/link`, { pdmaCode, link });
  return response.data;
};
