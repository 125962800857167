/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { reasonListResponse } from 'interfaces/Reason';
import { errorRedux } from 'interfaces/redux';
import { paramsQueryString } from 'utils/object';

import { getReasonList, getSelectableReasonList } from './service';

export const listReason = createAsyncThunk<reasonListResponse, paramsQueryString, { rejectValue: errorRedux }>(
  'partner/listReason',
  async (emailUser, { rejectWithValue }) => {
    try {
      const data = await getReasonList(emailUser);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const listSelectableReason = createAsyncThunk<reasonListResponse, undefined, { rejectValue: errorRedux }>(
  'partner/listSelectableReason',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getSelectableReasonList();
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IReason extends errorRedux {

  list: reasonListResponse;
}

const initialState: IReason = {
  status: 'idle',
  message: '',
  list: {
    status: '',
    document: [],
    message: '',
  },
};
const reason = createSlice({
  name: 'reason',
  initialState,
  reducers: {
    clearReasons: (state) => {
      state.status = 'idle';
      state.message = '';
      state.type = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listReason.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(listReason.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.list = action.payload;
        state.type = action.type;
      })
      .addCase(listReason.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(listSelectableReason.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(listSelectableReason.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.list = action.payload;
      })
      .addCase(listSelectableReason.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});

export const { clearReasons } = reason.actions;

export default reason.reducer;
