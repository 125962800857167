/* eslint-disable import/prefer-default-export */
import convertQueryParamsConditions, { paramsQueryString } from 'utils/object';

import { reasonListResponse } from 'interfaces/Reason';
import api from 'services';

export const getReasonList = async (params: paramsQueryString): Promise<reasonListResponse> => {
  const response = await api.get<reasonListResponse>('/reason', { params: convertQueryParamsConditions(params) });
  return response.data;
};

export const getSelectableReasonList = async (): Promise<reasonListResponse> => {
  const response = await api.get<reasonListResponse>(
    '/reason/selectable',
  );
  return response.data;
};
